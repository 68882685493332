import React from "react";

import styles from "../styles.module.scss";

interface InputProps {
  disabled?: boolean;
  value?: string;
  placeholder?: string;
}

export const Input: React.FC<InputProps> = ({
  value,
  placeholder,
  disabled = false,
}) => {
  return (
    <input
      className={styles.input}
      placeholder={placeholder}
      value={value}
      disabled={disabled}
    />
  );
};
