import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

import styles from "./styles.module.scss";

// <Button />

interface ButtonProps {
  children: JSX.Element | JSX.Element[];
}

export const Button: React.FC<ButtonProps> = ({ children }) => {
  const buttonClasses = classNames({
    [styles.button]: true,
  });

  return <button className={buttonClasses}>{children}</button>;
};

// <LinkButton />

interface LinkButtonProps {
  to: string;
  isMargined?: boolean;
  children: JSX.Element | JSX.Element[] | React.ReactElement | React.ReactNode;
}

export const LinkButton: React.FC<LinkButtonProps> = ({
  to,
  isMargined = false,
  children,
}) => {
  const buttonClasses = classNames({
    [styles.button]: true,
    [styles.margined]: isMargined,
  });

  return (
    <Link to={to} className={buttonClasses}>
      {children}
    </Link>
  );
};
