import React from 'react';
import styles from './styles.module.scss';
import { Container, Row, Col } from '../../elements/grid'
import { isMobile } from 'react-device-detect'

export const Home: React.FC = () => {
    const wh = isMobile ? 200 : 400;
    console.log(wh);
    return (
      <div className={styles.home}>
        <Container className={styles.homeContainer}>
            <Row>
                <Col>
                    <img width={wh} height={wh} src='/static/home/vortex.png' alt="Innermap" title="Innermap" className={styles.squiggle} />
                </Col>
            </Row>
            <Row>
                <Col>
                     <h1>innermap</h1>
                     <h2>Search Within, Change Throughout</h2>
                     <h4>Coming Spring 2024</h4>
                </Col>
            </Row>
        </Container>        
      </div>
    );
  };
  