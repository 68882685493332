import React from "react";
import { PlusCircle } from "lucide-react";

import { LinkButton } from "../../base/button";
import { Paths } from "../../../routes";
import styles from "./styles.module.scss";

interface Situation {
  id: string
  text: string
  loggedDate: Date
  color?: string
}

const situations: Situation[] = [
  {
    id: "one",
    text: "My employee said that she’s overworking",
    loggedDate: new Date("2023-01-15"),
    color: "#A7DEE7",
  },
  {
    id: "one",
    text: "My work performance is mediocre",
    loggedDate: new Date("2023-01-15"),
    color: "#FFDC81",
  },
  {
    id: "one",
    text: "I have to go to my therapy session",
    loggedDate: new Date("2023-01-15"),
    color: "#A7DEE7",
  },
  {
    id: "one",
    text: "My friend stood me up",
    loggedDate: new Date("2023-01-15"),
    color: "#FFDC81",
  },
  {
    id: "one",
    text: "I woke up at 8:30am",
    loggedDate: new Date("2023-01-15"),
    color: "#FFDC81",
  },
  {
    id: "one",
    text: "My mom gave me terrible advice",
    loggedDate: new Date("2023-01-15"),
    color: "#F1C5C0",
  },
  {
    id: "one",
    text: "Went on a shopping spree",
    loggedDate: new Date("2023-01-15"),
    color: "#FFDC81",
  },
  {
    id: "one",
    text: "Saw a photo of my ex on IG",
    loggedDate: new Date("2023-01-15"),
    color: "#FFDC81",
  },
  {
    id: "one",
    text: "Didn’t express my feelings",
    loggedDate: new Date("2023-01-15"),
    color: "#A7DEE7",
  },
  {
    id: "one",
    text: "My crush left his message on read",
    loggedDate: new Date("2023-01-15"),
    color: "#A7DEE7",
  },
  {
    id: "one",
    text: "Another car cut me off while driving",
    loggedDate: new Date("2023-01-15"),
    color: "#A7DEE7",
  },
  {
    id: "one",
    text: "Procrastinating at work",
    loggedDate: new Date("2023-01-15"),
    color: "#FFDC81",
  },
  {
    id: "one",
    text: "Getting ready to see friends",
    loggedDate: new Date("2023-01-15"),
    color: "#FFDC81",
  },
  {
    id: "one",
    text: "Overcommitting my time",
    loggedDate: new Date("2023-01-15"),
    color: "#FFDC81",
  },
  {
    id: "one",
    text: "Frazzled at the start of work",
    loggedDate: new Date("2023-01-15"),
    color: "#F2C3B5",
  },
  { id: "one", text: "Lashed out at my friend", loggedDate: new Date("2023-01-15"), color: "#FFDC81" },
  { id: "one", text: "Urges to binge", loggedDate: new Date("2023-01-15"), color: "#FFDC81", },
  {
    id: "one",
    text: "My dog died",
    loggedDate: new Date("2023-01-15"),
    color: "#B996EA",
  },
];

const SituationCard: React.FC<Situation> = ({ id, text, loggedDate, color }) => {
  const groupColor = color ? color : 'transparent';
  return (
    <div className={styles.situationCard}>
      <div className={styles.situationCardGroup}>
        <div className={styles.group} style={{ backgroundColor: groupColor }} />
      </div>
      <div className={styles.situationCardBody}>
        <div className={styles.loggedDate}>{loggedDate.toDateString()}</div>
        {text}
      </div>
    </div>
  );
};

export const Dashboard: React.FC = () => {
  return (
    <div className={styles.dashboard}>
      <div className={styles.header}>
        <h1>My Situations</h1>
      </div>
      <div className={styles.body}>
        {situations.map((situation) => (
          <SituationCard key={situation.id} {...situation} />
        ))}
      </div>
      <div className={styles.footer}>
        <LinkButton to={Paths.SITUATION}>
          <PlusCircle />
          Add Situation
        </LinkButton>
      </div>
    </div>
  );
};
