import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCreative } from "swiper";
import { ChevronDown, ChevronUp } from "lucide-react";
import classNames from "classnames";

import "swiper/css";
import styles from "./styles.module.scss";
import vortex from "./images/vortex.png";

// export const Slide: React.FC =

const LayerOne: React.FC = () => {
  const layerClasses = classNames({
    [styles.layer]: true,
    [styles.layerOne]: true,
  });

  return (
    <div className={layerClasses}>
      <div className={styles.layerPrev}>
        <div>
          {/*<div className={styles.helpStart}>*/}
          {/*  Swipe down to go through layers*/}
          {/*  <ChevronDown />*/}
          {/*</div>*/}
        </div>
      </div>
      <div className={styles.layerCurrent}>
        <div>
          <h2>Behaviour</h2>
          <p>Video gaming for hours</p>
        </div>
      </div>
      <div className={styles.layerNext}>
        <div>
          <h2>Automatic Thought</h2>
          <ChevronDown />
        </div>
      </div>
    </div>
  );
};

const LayerTwo: React.FC = () => {
  const layerClasses = classNames({
    [styles.layer]: true,
    [styles.layerTwo]: true,
  });

  return (
    <div className={layerClasses}>
      <div className={styles.layerPrev}>
        <div>
          <ChevronUp />
          <h2>Behaviour</h2>
        </div>
      </div>
      <div className={styles.layerCurrent}>
        <div>
          <h2>Automatic Thought</h2>
          <p>I hate my life</p>
        </div>
      </div>
      <div className={styles.layerNext}>
        <div>
          <h2>Secondary Emotions</h2>
          <ChevronDown />
        </div>
      </div>
    </div>
  );
};

const LayerThree: React.FC = () => {
  const layerClasses = classNames({
    [styles.layer]: true,
    [styles.layerThree]: true,
  });

  return (
    <div className={layerClasses}>
      <div className={styles.layerPrev}>
        <div>
          <ChevronUp />
          <h2>Automatic Thought</h2>
        </div>
      </div>
      <div className={styles.layerCurrent}>
        <div>
          <h2>Secondary Emotions</h2>
          <p>Emptiness</p>
        </div>
      </div>
      <div className={styles.layerNext}>
        <div>
          <h2>Core Belief</h2>
          <ChevronDown />
        </div>
      </div>
    </div>
  );
};

const LayerFour: React.FC = () => {
  const layerClasses = classNames({
    [styles.layer]: true,
    [styles.layerFour]: true,
  });

  return (
    <div className={layerClasses}>
      <div className={styles.layerPrev}>
        <div>
          <ChevronUp />
          <h2>Secondary Emotion</h2>
        </div>
      </div>
      <div className={styles.layerCurrent}>
        <div>
          <h2>Core Belief</h2>
          <p>I'm a failure</p>
        </div>
      </div>
      <div className={styles.layerNext}>
        <div>
          <h2>Primary Emotion</h2>
          <ChevronDown />
        </div>
      </div>
    </div>
  );
};

const LayerFive: React.FC = () => {
  const layerClasses = classNames({
    [styles.layer]: true,
    [styles.layerFive]: true,
  });

  return (
    <div className={layerClasses}>
      <div className={styles.layerPrev}>
        <div>
          <ChevronUp />
          <h2>Core Belief</h2>
        </div>
      </div>
      <div className={styles.layerCurrent}>
        <div>
          <h2>Primary Emotion</h2>
          <p>Shame</p>
        </div>
      </div>
      <div className={styles.layerNext}>
        <div>
          <h2>Core</h2>
          <ChevronDown />
        </div>
      </div>
    </div>
  );
};

const LayerSix: React.FC = () => {
  const layerClasses = classNames({
    [styles.layer]: true,
    [styles.layerSix]: true,
  });

  return (
    <div className={layerClasses}>
      <div className={styles.layerPrev}>
        <div>
          <ChevronUp />
          <h2>Primary Emotion</h2>
        </div>
      </div>
      <div className={styles.layerCurrent}>
        <div>
          <h2>Core</h2>
          <p>Pressure and invalidation</p>
        </div>
      </div>
      <div className={styles.layerNext} />
      {/*<div>*/}
      {/*  <h2>Core</h2>*/}
      {/*</div>*/}
    </div>
  );
};

interface LayersProps {
  onSlideTransitionEnd: (swiper: any) => void;
}

export const Layers: React.FC<LayersProps> = ({ onSlideTransitionEnd }) => {
  // const handleTransitionEnd = () => {
  //
  // }
  return (
    <Swiper
      spaceBetween={50}
      slidesPerView={1}
      direction={"vertical"}
      effect={"creative"}
      creativeEffect={{
        prev: {
          shadow: false,
          translate: [0, "-120%", -200],
          opacity: 0,
        },
        next: {
          shadow: false,
          translate: [0, "120%", -200],
          opacity: 1,
        },
      }}
      modules={[EffectCreative]}
      onSlideChange={onSlideTransitionEnd}
      // onSlideChange={() => console.log('slide change')}
      // onSwiper={(swiper) => console.log(swiper)}
    >
      <SwiperSlide>
        <LayerOne />
      </SwiperSlide>
      <SwiperSlide>
        <LayerTwo />
      </SwiperSlide>
      <SwiperSlide>
        <LayerThree />
      </SwiperSlide>
      <SwiperSlide>
        <LayerFour />
      </SwiperSlide>
      <SwiperSlide>
        <LayerFive />
      </SwiperSlide>
      <SwiperSlide>
        <LayerSix />
      </SwiperSlide>
    </Swiper>
  );
};

export const Vortexxxx: React.FC = () => {
  const [vortexExpanded, setVortexExpanded] = useState<boolean>(false);
  const vortexClasses = classNames({
    [styles.vortex]: true,
    [styles.isExpanded]: vortexExpanded,
  });

  return (
    <div className={vortexClasses}>
      <div className={styles.expanded}>
        {/*<Layers onReachedBeginning={() => { setVortexExpanded(false)}} />*/}
      </div>
      <button
        className={styles.vortexExpand}
        onClick={() => {
          alert("yup yup yup");
          setVortexExpanded(true);
        }}
      >
        <img src={vortex} alt="Innermap Logo" />
      </button>
    </div>
  );
};
