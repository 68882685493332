import React from "react";

import { Paths } from "../../../routes";
import { Card, CardBody, CardHeader, CardFooter } from "../../base/card";
import { LinkButton } from "../../base/button";
import { Input } from "../../base/form/input";

import logo from "../../logo.svg";
import styles from "./styles.module.scss";

interface AuthCardProps {
  children: JSX.Element | JSX.Element[];
}

const AuthCard: React.FC<AuthCardProps> = ({ children }) => {
  return <div className={styles.authCard}>{children}</div>;
};

export const Login: React.FC = () => {
  return (
    <div className={styles.auth}>
      <img className={styles.logo} src={logo} alt="Innermap Logo" />
      <AuthCard>
        <Card>
          <CardHeader>
            <h1>Welcome</h1>
            <p>Log in to continue</p>
          </CardHeader>
          <CardBody>
            <Input disabled={true} placeholder={"Email"} />
            <Input disabled={true} placeholder={"Password"} />
            <a className={styles.forgotPassword}>Forgot Password?</a>
            <LinkButton to={Paths.DASHBOARD}>Log In</LinkButton>
          </CardBody>
          <CardFooter>
            <p className={styles.signupOption}>
              Don't have an account? <a className={styles.signup}>Sign up</a>
            </p>
          </CardFooter>
        </Card>
      </AuthCard>
    </div>
  );
};
