import React from "react";
import classNames from "classnames";

import styles from "./styles.module.scss";

interface Props {
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

interface RowProps extends Props {
  reverse?: boolean;
}

interface ColProps extends Props {
  size?: number;
  tabletSize?: number;
  desktopSize?: number;
}

export const Container: React.FC<Props> = ({ className, style, children }) => {
  const classes = classNames({
    [styles.container]: true,
    [className ?? ""]: true,
  });
  return (
    <div className={classes} style={style}>
      {children}
    </div>
  );
};

export const Row: React.FC<RowProps> = ({
  reverse = false,
  className,
  style,
  children,
}) => {
  const classes = classNames({
    [styles.row]: true,
    [className ?? ""]: true,
    [styles.reverseRow]: reverse,
  });
  return (
    <div className={classes} style={style}>
      {children}
    </div>
  );
};

export const Col: React.FC<ColProps> = ({
  size,
  tabletSize,
  desktopSize,
  className,
  style,
  children,
}) => {
  const classes = classNames({
    [styles[`colDesktop${desktopSize ?? ""}`]]: desktopSize !== undefined,
    [styles[`colTablet${tabletSize ?? ""}`]]: tabletSize !== undefined,
    [styles[`col${size ?? ""}`]]: size !== undefined,
    [styles.col]: true,
    [className ?? ""]: true,
  });

  return (
    <div className={classes} style={style}>
      {children}
    </div>
  );
};
