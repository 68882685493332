import React, { useState } from "react";
import { Send } from "lucide-react";
import classNames from "classnames";

import { Input } from "../../base/form/input";
import { Layers } from "./vortex";
import styles from "./styles.module.scss";
import vortexImage from "./vortex/images/vortex.png";
import "swiper/css";

/**
 Affi: What’s going on Sia, what’s the situation?

Sia: It’s Sunday afternoon

Affi: Ah yes, and what thoughts are going through your mind?

Sia: I hate my life →automatic thought

Affi: It sounds like you might be feeling a bit anxious, hopeless or maybe even empty?

Sia: Yeah, I do feel empty → secondary emotions

Affi: Often when we feel empty it causes us to look for things to make that feeling go away. And behaviours pop up?

Sia: I ended up video gaming for four hours instead of getting anything productive done. → behaviour

Affi: Looking for an escape from the feeling?

Sia: Yeah, I guess so

Affi: I wonder where this feeling of emptiness is coming from. Would you like some help figuring out what’s below the surface?

Sia: Sure

Affi: It’s not uncommon for emptiness to be driven by deep feelings of shame, like we’re not acceptable or good enough. Does that feel familiar? → primary emotion

Sia: Yes, I feel like I’m not measuring up and that I’m a waste of space

Affi: Ugh, painful. Do you know what negative core beliefs tie to this feeling of shame?

Sia: That I’m a failure → negative core belief

Affi: No wonder your body is escaping to secondary emptiness. Feeling shame is so painful. When you think of your past history, do you know where this feeling of shame comes from?

Sia: My parents always had extremely high expectations for me and were highly critical of me

Affi: Sounds like you much have felt so much pressure and invalidation → core

Sia: Yeah, exactly

 */

const Bubbles: React.FC = () => {
  const data = [
    {
      id: 3,
      time: new Date(),
      message: "Yes, I feel like I’m not measuring up and that I’m a waste of space",
      isAffi: false,
    },
    {
      id: 3,
      time: new Date(),
      message: "It’s not uncommon for emptiness to be driven by deep feelings of shame, like we’re deficient in some way or not good enough. Does that feel familiar?",
      isAffi: true,
    },
    {
      id: 3,
      time: new Date(),
      message: "Yeah, I do feel empty",
      isAffi: false,
    },
    {
      id: 3,
      time: new Date(),
      message: "It sounds like you might be feeling a bit anxious, hopeless or maybe even empty?",
      isAffi: true,
    },
    {
      id: 3,
      time: new Date(),
      message: "I hate my life",
      isAffi: false,
    },
    {
      id: 3,
      time: new Date(),
      message:
        " Ah yes, and what thoughts are going through your mind?",
      isAffi: true,
    },
    {
      id: 3,
      time: new Date(),
      message: "It’s Sunday afternoon",
      isAffi: false,
    },
    {
      id: 2,
      time: new Date(),
      isAffi: true,
      message: "What’s going on Sia, what’s the situation?",
    },
  ];

  return (
    <div className={styles.bubbles}>
      {data.map((bubble) => {
        const bubbleClasses = classNames({
          [styles.bubbleBox]: true,
          [styles.isAffi]: bubble.isAffi,
        });
        return (
          <div key={bubble.id} className={bubbleClasses}>
            <div className={styles.bubble}>{bubble.message}</div>
          </div>
        );
      })}
    </div>
  );
};

interface VortexExpandedProps {
  isExpanded: boolean;
}

const VortexLayers: React.FC<VortexExpandedProps> = ({ isExpanded }) => {
  const classes = classNames({
    [styles.vortexLayers]: true,
    [styles.isExpanded]: isExpanded,
  });
  const handleReachBeginning = (swiper: any): void => {
    console.log(swiper);
  };
  return (
    <div className={classes}>
      <Layers onSlideTransitionEnd={handleReachBeginning} />
    </div>
  );
};

interface VortexProps {
  isExpanded: boolean;
  onExpand: () => void;
}

const Vortex: React.FC<VortexProps> = ({ isExpanded = false, onExpand }) => {
  const classes = classNames({
    [styles.vortex]: true,
    [styles.isExpanded]: isExpanded,
  });
  return (
    <div className={classes}>
      <button onClick={onExpand}>
        <img src={vortexImage} alt="Innermap Logo" />
      </button>
    </div>
  );
};

interface ChatBoxProps {
  isVisible: boolean;
  onCollapseChatBox: () => void;
}

const ChatBox: React.FC<ChatBoxProps> = ({ isVisible, onCollapseChatBox }) => {
  const classes = classNames({
    [styles.chatBox]: true,
    [styles.isVisible]: isVisible,
  });
  return (
    <div className={classes}>
      <button
        className={styles.chatBoxCollapseTrigger}
        onClick={() => {
          onCollapseChatBox();
        }}
      />
      <div className={styles.body}>
        <Bubbles />
      </div>
      <div className={styles.inputBox}>
        <Input placeholder="Message" />
        <button
          onClick={() => {
            console.log("clicked");
          }}
        >
          <Send />
        </button>
      </div>
    </div>
  );
};

interface ChatExpandTriggerProps {
  isVisible: boolean;
  onExpandChatBox: () => void;
}

const ChatExpandTrigger: React.FC<ChatExpandTriggerProps> = ({
  isVisible,
  onExpandChatBox,
}) => {
  const classes = classNames({
    [styles.chatBoxExpandTrigger]: true,
    [styles.isVisible]: isVisible,
  });
  return (
    <div className={classes}>
      <button
        onClick={() => {
          onExpandChatBox();
        }}
      >
        <div>Back to Affi</div>
      </button>
    </div>
  );
};

export const Situation: React.FC = () => {
  const [vortexIsExpanded, setVortexIsExpanded] = useState<boolean>(false);
  const [chatIsExpanded, setChatIsExpanded] = useState<boolean>(true);
  return (
    <div className={styles.situation}>
      <Vortex
        isExpanded={vortexIsExpanded}
        onExpand={() => {
          setVortexIsExpanded(true);
        }}
      />
      <VortexLayers isExpanded={vortexIsExpanded} />
      <ChatBox
        isVisible={chatIsExpanded}
        onCollapseChatBox={() => {
          setChatIsExpanded(false);
        }}
      />
      <ChatExpandTrigger
        isVisible={!chatIsExpanded}
        onExpandChatBox={() => {
          setChatIsExpanded(true);
          setVortexIsExpanded(false);
        }}
      />
    </div>
  );
};
