import React from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";

import { AnimatePresence } from "framer-motion";

import { Login, Dashboard, Situation, Home } from "./components/views";
import { Layout } from "./components/base/layout";

export enum Paths {
  HOME = "/",
  LOGIN = "/demo/",
  DASHBOARD = "/dashboard/",
  SITUATION = "/situation/",
}

const AnimatedRoutes = () => {
  const location = useLocation();
  return (
    <Layout>
      <AnimatePresence>
        <Routes location={location} key={location.pathname}>
          <Route path={Paths.HOME} element={<Home />} />
          <Route path={Paths.LOGIN} element={<Login />} />
          <Route path={Paths.DASHBOARD} element={<Dashboard />} />
          <Route path={Paths.SITUATION} element={<Situation />} />
        </Routes>
      </AnimatePresence>
    </Layout>
  );
};

export const AppRouter = () => {
  return (
    <BrowserRouter>
      <AnimatedRoutes />
    </BrowserRouter>
  );
};
