import React from "react";

import styles from "./styles.module.scss";

interface CardProps {
  children: JSX.Element | JSX.Element[];
}

export const Card: React.FC<CardProps> = ({ children }) => {
  return <div className={styles.card}>{children}</div>;
};

export const CardHeader: React.FC<CardProps> = ({ children }) => {
  return <div className={styles.cardHeader}>{children}</div>;
};

export const CardBody: React.FC<CardProps> = ({ children }) => {
  return <div className={styles.cardBody}>{children}</div>;
};

export const CardFooter: React.FC<CardProps> = ({ children }) => {
  return <div className={styles.cardFooter}>{children}</div>;
};
